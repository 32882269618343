import dayjs from 'dayjs'

export enum ENDROITS {
  EXTERIEUR = 'EXTERIEUR',
  INTERIEUR = 'INTERIEUR',
  NONE = 'NONE',
}

export interface CustomForm {
  nom?: string
  prenom?: string
  email?: string
  telephone?: string
  cp?: string
  ville?: string
  adresse?: string
  date?: string
  heure?: string
  personne?: number
  accept?: boolean
  message?: string
  endroit?: ENDROITS
}

export enum JOURS_DE_LA_SEMAINE {
  DIMANCHE = 0,
  LUNDI = 1,
  MARDI = 2,
  MERCREDI = 3,
  JEUDI = 4,
  VENDREDI = 5,
  SAMEDI = 6,
}

export const JoursNumeros = new Map<JOURS_DE_LA_SEMAINE, keyof Semaine>([
  [JOURS_DE_LA_SEMAINE.LUNDI, 'lundi'],
  [JOURS_DE_LA_SEMAINE.MARDI, 'mardi'],
  [JOURS_DE_LA_SEMAINE.MERCREDI, 'mercredi'],
  [JOURS_DE_LA_SEMAINE.JEUDI, 'jeudi'],
  [JOURS_DE_LA_SEMAINE.VENDREDI, 'vendredi'],
  [JOURS_DE_LA_SEMAINE.SAMEDI, 'samedi'],
  [JOURS_DE_LA_SEMAINE.DIMANCHE, 'dimanche'],
])

export interface Jour {
  date?: string
  midi: number[] | string
  'limite-midi': number
  soir: number[] | string
  'limite-soir': number
}

export interface Semaine {
  lundi: Jour
  mardi: Jour
  mercredi: Jour
  jeudi: Jour
  vendredi: Jour
  samedi: Jour
  dimanche: Jour
}

export interface Horaires {
  nom: string
  horaires: Semaine
  exceptions: Jour[]
}

export const firstUpperCase = (str: string = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getJSON = async (ville: string): Promise<Horaires> => {
  let json: Horaires
  if (process.env.NODE_ENV === 'production') {
    json = (await fetch(`${process.env.VUE_APP_JSON_PATH}${ville}.json`)
      .then((res) => res.json())
      .then((data) => data)) as Horaires
  } else {
    json = require(`../${ville}.json`)
  }
  return json
}

export const getDateInfo = async (
  ville: string,
  crudeDate: string,
): Promise<Jour> => {
  const date = dayjs(crudeDate || '')
  const dateFormatted = date.format('DD/MM/YYYY')
  const json = await getJSON(ville)

  let limites: Jour

  if (json.exceptions.map((e) => e.date || '').includes(dateFormatted)) {
    limites = json.exceptions.find((e) => e.date === dateFormatted) as Jour
  } else {
    const jour = JoursNumeros.get(date.day())
    limites = json.horaires[jour as keyof Semaine]
  }

  return limites
}

export const numberToHeure = (number: number): string => {
  return `${Math.floor(number)}:${(number % 1) * 60 ? (number % 1) * 60 : '00'}`
}

export const heureToNumber = (heure: string): number => {
  return (heure || '00:00')
    .split(':')
    .map((string, index) => +string / Math.pow(60, index))
    .reduce((acc, cur) => acc + cur, 0)
}

export const fillHeure = (limites: Jour, date: string): string[] => {
  const res: string[] = []
  const today = dayjs()

  if (today.format('DD/MM/YYY') === dayjs(date).format('DD/MM/YYY')) {
    res.push(TIMES.MIDI)
    if (limites['limite-midi'] > today.hour() + today.minute() / 60) {
      if (limites.midi.length) {
        if (typeof limites.midi === 'string') {
          res.push(limites.midi as string)
        } else if (limites.midi instanceof Array) {
          for (
            let heure = limites.midi[0];
            heure <= limites.midi[1];
            heure += 0.25
          ) {
            res.push(numberToHeure(heure))
          }
        }
      }
    }
    res.push(TIMES.SOIR)
    if (limites['limite-soir'] > today.hour() + today.minute() / 60) {
      if (limites.soir.length) {
        if (typeof limites.soir === 'string') {
          res.push(limites.soir as string)
        } else if (limites.soir instanceof Array) {
          for (
            let heure = limites.soir[0];
            heure <= limites.soir[1];
            heure += 0.25
          ) {
            res.push(numberToHeure(heure))
          }
        }
      }
    }
  } else {
    res.push(TIMES.MIDI)
    if (limites.midi.length) {
      if (typeof limites.midi === 'string') {
        res.push(limites.midi as string)
      } else if (limites.midi instanceof Array) {
        for (
          let heure = limites.midi[0];
          heure <= limites.midi[1];
          heure += 0.25
        ) {
          res.push(numberToHeure(heure))
        }
      }
    }
    res.push(TIMES.SOIR)
    if (limites.soir.length) {
      if (typeof limites.soir === 'string') {
        res.push(limites.soir as string)
      } else if (limites.soir instanceof Array) {
        for (
          let heure = limites.soir[0];
          heure <= limites.soir[1];
          heure += 0.25
        ) {
          res.push(numberToHeure(heure))
        }
      }
    }
  }

  return res
}

export enum TIMES {
  MIDI = '-- Midi --',
  SOIR = '-- Soir --',
}
