import { DigUiToaster } from '@digithia/ui'

export interface Toast {
  message: string
  type?: 'success' | 'warning' | 'info' | 'error'
  success?: boolean
}

export class Toaster {
  static lifespan = '7500'
  static toasterEl: DigUiToaster = document.querySelector(
    'dig-ui-toaster',
  ) as DigUiToaster

  static toast(toast: Toast | string) {
    if (!this.toasterEl) {
      Toaster.toasterEl = document.querySelector(
        'dig-ui-toaster',
      ) as DigUiToaster
    }
    const toastEl = document.createElement('dig-ui-toast')
    toastEl.setAttribute('lifespan', Toaster.lifespan)
    if (typeof toast === 'string') {
      toastEl.setAttribute('type', 'info')
      toastEl.append(toast)
      Toaster.toasterEl?.prepend(toastEl)
    } else {
      if (toast.type) {
        toastEl.setAttribute('type', toast.type)
      } else if ('success' in toast) {
        toastEl.setAttribute('type', toast.success ? 'success' : 'error')
      } else {
        toastEl.setAttribute('type', 'info')
      }

      toastEl.append(toast.message)
      Toaster.toasterEl?.prepend(toastEl)
    }
  }
}
