
import { PropType, SetupContext } from 'vue'
import { CustomForm, ENDROITS } from '../model/Form'

export default {
  props: {
    form: Object as PropType<CustomForm>,
    heures: Array as PropType<string[]>,
  },
  emits: ['update', 'validate'],
  setup(
    props: Readonly<{
      form?: CustomForm
      heures?: string[]
    }>,
    context: SetupContext,
  ) {
    const update = (key: keyof CustomForm, value: never) => {
      if (key === 'heure' && !(value as string).match('[0-9]*:[0-9]*')) {
        context.emit('update', { key, value: '' })
      } else {
        context.emit('update', { key, value })
      }
    }

    const sendForm = () => {
      console.log('send form')
      context.emit('validate')
    }

    return { update, sendForm, ENDROITS }
  },
}
