
import { defineComponent, onMounted, PropType, Ref, ref } from 'vue'
import FormClient from './FormClient.vue'
import {
  CustomForm,
  ENDROITS,
  fillHeure,
  getDateInfo,
  heureToNumber,
  numberToHeure,
  TIMES,
  firstUpperCase,
} from '../model/Form'
import { DigInputText } from '@digithia/input/text'
import { DigInputController } from '@digithia/input/common'
import dayjs from 'dayjs'
import { Toaster } from '../model/Toaster'
import { apiHandler } from '../model/ApiHandler'

const DEFAULT_FORM: CustomForm = {
  nom: '',
  prenom: '',
  email: '',
  telephone: '',
  cp: '',
  ville: '',
  adresse: '',
  date: '',
  heure: '',
  personne: undefined,
  accept: false,
  message: '',
  endroit: undefined,
}

const FILLED_FORM: CustomForm = {
  nom: 'Letullier',
  prenom: 'Thibert',
  email: 't.letullier@digithia.fr',
  telephone: '0658780653',
  cp: '57740',
  ville: 'Longeville',
  adresse: `22 rue de nieuil l'espoir`,
  date: '2021-07-09',
  heure: '12:00',
  personne: undefined,
  accept: true,
  message: '',
  endroit: ENDROITS.EXTERIEUR,
}

export default defineComponent({
  name: 'Restau',
  components: {
    FormClient,
  },
  props: {
    ville: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(
    props: Readonly<{
      ville: string
    }>,
  ) {
    const form: Ref<CustomForm> = ref(
      process.env.VUE_APP_FILL === 'yes' ? FILLED_FORM : DEFAULT_FORM,
    )

    const heures: Ref<string[]> = ref([TIMES.MIDI, TIMES.SOIR])
    const finish = ref(false)

    let inputDate: DigInputText

    onMounted(() => {
      console.log(props.ville)
      console.log(process.env)
      inputDate = document.querySelector(
        '.input-date',
      ) as unknown as DigInputText
    })

    const update = async (event: { key: keyof CustomForm; value: never }) => {
      form.value[event.key] = event.value

      const today = dayjs()
      const jourInfo = await getDateInfo(props.ville, form.value.date || '')

      if (event.key === 'date') {
        if (dayjs(form.value.date).add(1, 'day').isBefore(today)) {
          inputDate.validator = () => false
          inputDate.errors.validator = `La date est dépassée !`
          Toaster.toast({
            type: 'error',
            message: `La date est dépassée !`,
          })
          heures.value = [TIMES.MIDI, TIMES.SOIR]
        } else {
          inputDate.validator = () => true
          heures.value = fillHeure(jourInfo, form.value.date || '')

          if (!heures.value.includes(form.value.heure || '')) {
            form.value.heure = ''
          }
        }
      }
    }

    const validate = async () => {
      if (
        DigInputController.checkInputsValidity('.form-client') &&
        form.value.accept &&
        form.value.heure?.match('[0-9]*:[0-9]*')
      ) {
        const today = dayjs()

        if (
          today.format('DD/MM/YYYY') !==
          dayjs(form.value.date || '').format('DD/MM/YYYY')
        ) {
          return sendToServer()
        }

        const jourInfo = await getDateInfo(props.ville, form.value.date || '')
        const heureNumber = heureToNumber(form.value.heure || '')

        if (
          jourInfo.midi instanceof Array &&
          jourInfo.midi.some((l) => l <= heureNumber) &&
          jourInfo.midi.some((l) => l >= heureNumber)
        ) {
          if (jourInfo['limite-midi'] < today.hour()) {
            Toaster.toast({
              type: 'error',
              message: `Il est trop tard pour réserver ce midi (maximum ${numberToHeure(
                jourInfo['limite-midi'],
              )})`,
            })
          } else {
            sendToServer()
          }
        } else if (
          jourInfo.soir instanceof Array &&
          jourInfo.soir.some((l) => l <= heureNumber) &&
          jourInfo.soir.some((l) => l >= heureNumber)
        ) {
          if (jourInfo['limite-soir'] < today.hour()) {
            Toaster.toast({
              type: 'error',
              message: `Il est trop tard pour réserver ce soir (maximum ${numberToHeure(
                jourInfo['limite-soir'],
              )})`,
            })
          } else {
            sendToServer()
          }
        }
      } else {
        Toaster.toast({ type: 'error', message: `Le formulaire est invalide` })
      }
    }

    const sendToServer = async () => {
      const res = await apiHandler.post(`${props.ville}.php`, form.value)
      Toaster.toast(res)
      if (res.success) {
        finish.value = true
      }
    }

    const reinit = () => {
      form.value = DEFAULT_FORM
      finish.value = false
    }

    return { form, update, validate, heures, finish, reinit, firstUpperCase }
  },
})
