export interface ServerResponse {
  code: number
  data?: unknown
  error?: string | unknown
  success?: boolean
  type?: string
  message?: string
  status?: number
}

export const ERROR_SERVER = `Une erreur est suvenue sur le serveur.`

class ApiClient {
  baseUrl = process.env.VUE_APP_API_PATH

  lang = 'fr'

  headers = {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': this.lang,
  }

  async get(path: string) {
    const request = {
      method: 'GET',
      headers: {
        ...this.headers,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, 'GET')
        return json
      })
      .catch((error) => {
        this.handleServerError(error, path, 'GET')
        return { type: 'error', message: ERROR_SERVER }
      })
  }

  async post(path: string, body: unknown = {}) {
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response: Response) => {
        const json = await response.json()
        this.logResponse(json, path, 'POST')
        return json
      })
      .catch((error) => {
        this.handleServerError(error, path, 'POST')
        return { type: 'error', message: ERROR_SERVER }
      })
  }

  async put(path: string, body: unknown = {}) {
    const request = {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, 'PUT')
        return json
      })
      .catch((error) => {
        this.handleServerError(error, path, 'PUT')
        return { type: 'error', message: ERROR_SERVER }
      })
  }

  async delete(path: string, body: unknown = {}) {
    const request = {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, 'DELETE')
        return json
      })
      .catch((error) => {
        this.handleServerError(error, path, 'DELETE')
        return { type: 'error', message: ERROR_SERVER }
      })
  }

  logResponse(json: ServerResponse, url: string, method: string) {
    console.log(`Server call on ${method} ${url} and response with : `, {
      ...json,
      data: typeof json.data === 'string' ? json.data.slice(0, 100) : json.data,
    })
  }

  handleServerError(error: Response, url: string, method: string) {
    console.log(
      `Une erreur est survenue sur le serveur on ${method} ${this.baseUrl}${url} : `,
      error,
    )
  }
}

export const apiHandler = new ApiClient()
