
import { defineComponent } from 'vue'
import Restau from './components/Restau.vue'

export default defineComponent({
  name: 'App',
  components: {
    Restau,
  },
  setup() {
    const ville = process.env.VUE_APP_VILLE

    console.log(process.env.VUE_APP_JSON_PATH)

    return { ville }
  },
})
