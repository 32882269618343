<template>
  <div class="form-client">
    <div class="col-2">
      <dig-input-text
        id="input-nom"
        :value="form.nom"
        @input="update('nom', $event.target.value)"
        required
      >
        Nom
      </dig-input-text>
      <dig-input-text
        id="input-prenom"
        :value="form.prenom"
        @input="update('prenom', $event.target.value)"
        required
      >
        Prénom
      </dig-input-text>
    </div>

    <div class="col-2">
      <dig-input-text
        id="input-email"
        :value="form.email"
        @input="update('email', $event.target.value)"
        required
        type="email"
      >
        Email
      </dig-input-text>
      <dig-input-text
        id="input-telephone"
        :value="form.telephone"
        @input="update('telephone', $event.target.value)"
        required
      >
        Téléphone
      </dig-input-text>
    </div>

    <dig-input-text
      id="input-adresse"
      :value="form.adresse"
      @input="update('adresse', $event.target.value)"
    >
      Adresse
    </dig-input-text>
    <div class="col-2-bis">
      <dig-input-text
        id="input-cp"
        :value="form.cp"
        @input="update('cp', $event.target.value)"
      >
        Code postal
      </dig-input-text>
      <dig-input-text
        id="input-ville"
        :value="form.ville"
        @input="update('ville', $event.target.value)"
      >
        Ville
      </dig-input-text>
    </div>

    <div class="col-3">
      <dig-input-text
        class="input-date"
        :value="form.date"
        @input="update('date', $event.target.value)"
        type="date"
        required
      >
        Date
      </dig-input-text>
      <dig-input-select-picker
        class="input-heure"
        label="Heure"
        :value="form.heure"
        @select="update('heure', $event.detail)"
        max-shown="10"
        required
        v-if="heures.length"
      >
        <dig-input-select-picker-option
          v-for="heure of heures"
          :key="heure"
          :value="heure"
          :class="{
            'option-disabled': !heure.match('[0-9]*:[0-9]*'),
          }"
        >
          {{ heure }}
        </dig-input-select-picker-option>
      </dig-input-select-picker>
      <dig-input-number
        :value="form.personne"
        @input="update('personne', $event.target.value)"
        disabled-decimal
        disabled-negative
        required
      >
        Nombre de personne
      </dig-input-number>
    </div>

    <dig-input-radio-wrapper
      :value="form.endroit"
      @input="update('endroit', $event.detail)"
      label="Je préfère : "
    >
      <dig-input-radio :value="ENDROITS.INTERIEUR">
        Manger en salle
      </dig-input-radio>
      <dig-input-radio :value="ENDROITS.EXTERIEUR">
        Manger en terrasse
      </dig-input-radio>
      <dig-input-radio :value="ENDROITS.NONE">
        Je n'ai pas de préférence
      </dig-input-radio>
    </dig-input-radio-wrapper>

    <dig-input-textarea
      :value="form.message"
      @input="update('message', $event.target.value)"
      placeholder=" "
    >
      Votre message (infos et demandes diverses : Occasion particulière, chaise
      bébé, accès PMR, place poussette, groupes,...)
    </dig-input-textarea>

    <dig-input-checkbox
      :checked="form.accept"
      @change="update('accept', $event.detail)"
      required
    >
      Je confirme avoir pris connaissance des informations relatives au recueil
      et à la gestion de mes données via ce formulaire.
    </dig-input-checkbox>

    <p>
      Les informations recueillies par Jine Restaurant & Bar via ce formulaire
      sont utilisées pour traiter votre demande particulière et vous faire
      parvenir des informations commerciales liées à notre société. Pour
      demander la modification ou la suppression de vos informations, utilisez
      ce même formulaire.
    </p>

    <p>
      En cliquant sur «Réserver», vous acceptez les conditions générales
      d’utilisation du restaurant. Les restaurants Jine réalisent un traitement
      de données personnelles ayant pour finalité la gestion des utilisateurs du
      site, la gestion des réservations et la prospection commerciale. Ces
      données sont transférées au restaurant, et à ses prestataires de service.
      Conformément à la loi du 6 janvier 1978 modifiée, vous disposez d’un droit
      d’accès, d’interrogation, de rectification et d’opposition aux
      informations qui vous concernent. Pour exercer ce droit, contactez-nous
      via le formulaire.
    </p>

    <div class="button-container">
      <button @click="sendForm()">Envoyer la demande de réservation</button>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, SetupContext } from 'vue'
import { CustomForm, ENDROITS } from '../model/Form'

export default {
  props: {
    form: Object as PropType<CustomForm>,
    heures: Array as PropType<string[]>,
  },
  emits: ['update', 'validate'],
  setup(
    props: Readonly<{
      form?: CustomForm
      heures?: string[]
    }>,
    context: SetupContext,
  ) {
    const update = (key: keyof CustomForm, value: never) => {
      if (key === 'heure' && !(value as string).match('[0-9]*:[0-9]*')) {
        context.emit('update', { key, value: '' })
      } else {
        context.emit('update', { key, value })
      }
    }

    const sendForm = () => {
      console.log('send form')
      context.emit('validate')
    }

    return { update, sendForm, ENDROITS }
  },
}
</script>

<style scoped lang="scss">
.col-2 {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
}

.col-2-bis {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 200px 1fr;
}

.col-3 {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

p {
  font-size: 13px;
}

dig-input-select-picker-option {
  --dig-input-select-picker-option-padding: 4px 8px;
}

dig-input-checkbox {
  margin-bottom: 10px;
}

.option-disabled {
  pointer-events: none;
}

@media screen and (max-width: 900px) {
  .col-2 {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .col-2-bis {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .col-3 {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  dig-input-checkbox {
    margin: 30px 0px;
  }
}
</style>
