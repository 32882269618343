<template>
  <dig-ui-toaster></dig-ui-toaster>
  <restau :ville="ville"></restau>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Restau from './components/Restau.vue'

export default defineComponent({
  name: 'App',
  components: {
    Restau,
  },
  setup() {
    const ville = process.env.VUE_APP_VILLE

    console.log(process.env.VUE_APP_JSON_PATH)

    return { ville }
  },
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background-color: rgb(29, 29, 29);
  color: white;
  padding: 10px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;
}

.button-container {
  display: grid;
}

.center {
  text-align: center;
}
</style>
