import { createApp } from 'vue'
import App from './App.vue'

import { DigInputConfig } from '@digithia/input/common'
DigInputConfig.SEARCH_LABEL = `Rechercher`

import '@digithia/input/text'
import '@digithia/input/select'
import '@digithia/input/number'
import '@digithia/input/checkbox'
import '@digithia/input/textarea'
import '@digithia/input/radio'
import '@digithia/ui'

createApp(App).mount('#app')
